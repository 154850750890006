<template>
  <div class="resetPasswordPage bgfff">
    <div class="app h5MaxWidth">
      <header>
        <div class="header h5MaxWidth clear display alignCenter">
          <div
            class="back display alignCenter justifyCenter"
            onclick="window.history.go(-1)"
          >
            <i class="iconfont icon-arrow-left"></i>
          </div>
          <h2 class="flex1">Change Login Password</h2>
          <div class="navskip" id="sideMenu"></div>
        </div>
      </header>
      <div class="main">
        <div class="resetPassword forms">
          <form id="resetPassword" action="#" method="post" onsubmit="">
            <ul>
              <li class="oldPasswordBox">
                <div class="inputArea display alignCenter">
                  <div class="leftSideBox display alignCenter">
                    <i class="i_inputIcon iconfont icon-lock-2"></i>
                  </div>
                  <div class="trueInpt display alignCenter flex1">
                    <input
                      type="password"
                      v-model="login_OldPassword"
                      placeholder="Old Password"
                      class="password fillArea eyeClose flex1"
                    />
                    <!-- <div
                      id="eye"
                      class="eye secret rightSideBox display alignCenter"
                    >
                      <i class="r iconfont icon-eye"></i>
                      <i class="s iconfont icon-eye-slash"></i>
                    </div> -->
                  </div>
                </div>
              </li>
              <li>
                <div class="inputArea display alignCenter">
                  <div class="leftSideBox display alignCenter">
                    <i class="i_inputIcon iconfont icon-lock"></i>
                  </div>
                  <div class="trueInpt display alignCenter flex1">
                    <input
                      type="password"
                      v-model="login_NewPassword"
                      id="login_NewPassword"
                      placeholder="New Password"
                      class="password fillArea eyeClose flex1"
                    />
                  </div>
                </div>
              </li>
            </ul>

            <div class="actionBar display alignCenter alignStretch justifyEnd">
              <div
                class="changeBtn clickBtn display alignCenter justifyCenter"
                @click="resetPassword()"
              >
                <p>Confirm</p>
                <i class="iconfont icon-arrow-right-3"></i>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      login_OldPassword: "",
      login_NewPassword: "",
    };
  },
  components: {},
  methods: {
    resetPassword() {
      this.getAjax(
        "api/user/password",
        {
          uid: this.uid,
          oldPass: this.login_OldPassword,
          newPass: this.login_NewPassword,
        },
        "post"
      ).then((res) => {
        if (res.code === 200) {
          this.tips("success!");
        } else {
          this.tips(res.msg, "warning");
        }
      });
    },
  },
  mounted() {
    this.uid = this.getItem("uid");
  },
};
</script>

<style scoped lang="less">
</style>
